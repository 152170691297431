

import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

function CancelPage({ location }) {
  return (
    <Layout location={location}>
      <Seo title="Cancellation policy" />
      <section className="mt-8 lg:mt-18 mb-20">
        <header>
          <h1>Cancellation policy</h1>
        </header>
        <article className="pt-16 pb-64 prose lg:prose-xl">
          <p>Notice of a training candidate's intent to cancel a registration must be made in writing to the training provider and is subject to the following terms:</p>
          <ul className="pl-10 my-4 list-disc">
            <li>Training candidates whose cancellation request is received in excess of 14 days prior to the start date of the training activity will incur no liabilities.</li>
            <li>Training candidates whose cancellation request is received within 8 to 14 days prior to the start date of the training activity will incur an administration fee equal to 50% of the obligated fee.</li>
            <li>Training candidates whose cancellation request is received within 7 days prior to the start date or after the commencement of the training activity will incur an administration fee equal to 100% of the obligated fee.</li>
            <li>One substitute is permitted per registrant for the entire course; daily substitutions are not permitted. Training provider be notified prior to the first day of the course when a substitution will occur.</li>
            <li>Training provider will also allow the registration fee to be applied to a different training provider sponsored course. This request must be made in writing, subject to approval by training provider, and can be used during a period of time not exceeding 18 months from the original course registration.</li>
            <li>Training provider reserves the right to cancel courses, change course location, change instructors, or change course content. In the event that a course must be canceled or the location of the course changed, registrants will be informed 21 days prior to the start of the course.</li>
            <li>In case of trainer's illness or other force de majeure circumstances that occurred less 21 days before the course, training provider will offer alternative dates for the course or full refund.</li>
          </ul>
          <p>For other cases, training provider offers the <Link to="/complain/">complaints procedure</Link>.</p>
        </article>
      </section>
    </Layout>
  )
}

export default CancelPage
